<template>
  <cl-sidebar v-if="this.selectedCustomer || this.authUser">
    <template v-slot:company-logo="{ isClosed }">
      <router-link v-if="uiLogo" :to="routeToHome" data-test-id="navbar-home">
        <img
          class="tw-max-w-[200px]"
          :class="{ 'tw-hidden': isClosed }"
          :src="uiLogo"
          alt="logo"
        />
      </router-link>
      <div v-else role="status" class="tw-animate-pulse">
        <div
          class="tw-h-14 tw-rounded tw-bg-aside-400"
          :class="{ 'tw-w-60': !isClosed }"
        ></div>
      </div>
    </template>

    <template v-slot:dropdown-select="{ isClosed }">
      <slot name="dropdown" :isClosed="isClosed" />
    </template>
    <template v-slot:side-nav="{ isClosed }">
      <cl-sidebar-nav>
        <router-link
          v-for="item in transformedNavItems"
          :key="item.id"
          :to="item.id"
          v-slot="{ href, navigate }"
          class="tw-group tw-flex tw-w-full tw-cursor-pointer tw-flex-col tw-items-center"
          :class="item.isActive ? 'tw-bg-sidebar-item-active' : ''"
        >
          <cl-sidebar-nav-item-parent
            :href="href"
            :isClosed="isClosed"
            :navigate="navigate"
            :icon="item.icon"
            :isActive="item.isActive"
            :data-test-id="`sidebar-nav-parent-${item.label}`"
            :label="item.label"
          >
            <template #pill v-if="item.pill">
              <cl-pill :variant="'success'">{{ item.pill }}</cl-pill>
            </template>
          </cl-sidebar-nav-item-parent>
          <!-- subnav  child  -->
          <div
            :class="
              isClosed
                ? 'tw-absolute tw-left-[100%] tw-hidden group-hover:tw-block'
                : 'tw-w-full'
            "
          >
            <ul v-if="item.children && item.isActive">
              <router-link
                v-for="child in item.children"
                :key="child.id"
                :to="child.id"
                v-slot="{ href, navigate }"
              >
                <cl-sidebar-nav-item-child
                  :href="href"
                  :isClosed="isClosed"
                  :navigate="navigate"
                  :isActive="child.isActive"
                  :data-test-id="`sidebar-nav-child-${child.label}`"
                  :label="child.label"
                />
              </router-link>
            </ul>
          </div>
        </router-link>
      </cl-sidebar-nav>
    </template>
    <template v-slot:info-section="{ isClosed }">
      {{ isClosed }}
    </template>
  </cl-sidebar>
</template>
<script>
import { mapGetters } from "vuex";

import { ACCOUNT_TYPE } from "@/constants";

export default {
  props: {
    navItems: Array,
    isClosed: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    ...mapGetters("authentication", [
      "selectedCustomer",
      "authUser",
      "selectedAccount",
    ]),
    ...mapGetters("userInterface", ["interface", "uiLogo"]),
    routeToHome() {
      const type = this.selectedAccount?.account_type;
      switch (type) {
        case ACCOUNT_TYPE.SYSTEM:
          return "/system";
        case ACCOUNT_TYPE.SALE:
          return "/sales";
        case ACCOUNT_TYPE.MSP:
          return "/msp";
        case ACCOUNT_TYPE.CUSTOMER:
          return "/customer";
        case ACCOUNT_TYPE.DOMAIN:
          return "/domain";
        case ACCOUNT_TYPE.USER:
          return "/user";
        default:
          return "/";
      }
    },

    transformedNavItems() {
      const routePath = this.$route.path;
      const setIsActive = (link) => routePath.includes(link);

      const items = this.$props.navItems.map((item) => ({
        ...item,
        isActive: setIsActive(item.id),
        children: item.children?.map((child) => ({
          ...child,
          isActive: setIsActive(child.id),
        })),
      }));

      return items;
    },
  },
  methods: {
    onNavItemClick(payload) {
      this.$router.push(payload);
    },
  },
};
</script>
